p{
    color: #000;
}

.talkwithexpert{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 70%;
}

.expert-text{
    color: #000;
    font-family: "dmsans";
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.hire-boxes{
  display:flex;
  flex-direction:row;
  gap:4rem;
  margin-top:4rem;
}


@media (min-width: 0px) and (max-width: 576px) {
  .hire-boxes{
    flex-direction: column;
  }

  .talkwithexpert{
    width: 100%;
  }

}