.art-info-text{
    color: #1C1C1C;
font-family:"dmsans";
font-size: 0.5625rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.cap-title-text{
color: #1C1C1C;
font-family: "dmsans";
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.cap-data-date{
color: #1C1C1C;
font-family:"dmsans";
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}