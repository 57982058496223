
@font-face {
    font-family: 'mont';
    src: url(../../../public/Mont/Montserrat-Medium.ttf);
}
@font-face {
    font-family: 'boldmont';
    src: url(../../../public/Mont/Montserrat-Bold.ttf);
}

.membership-box{
display: "flex";
flex-direction: "row";
}

  .reviews-container {
    display: flex;
    overflow-x: auto;
    padding: 10px;
    margin-bottom: 20px;
    /* Ensure a specific height for the container to enable vertical scrolling if needed */
    max-height: 200px; /* Set the maximum height for vertical scrolling */
  }
  
  .review-card {
    flex: 0 0 auto;
    width: 300px;
    margin-right: 10px;
    border: 1px solid #ccc;
    padding: 10px;
  }

.membership{
    margin-left: 250px;
    margin-top: 4vh;    
    width: 60%;
}

.membership-packages{
 display: flex;
 flex-direction:row;
}




.membership-subtext{
color: #1C1C1C;
font-family: "dmsans";
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}


.plan-text{
color: #1C1C1C;
font-family:"boldsans";
font-size: 1.125rem;
font-style: normal;
font-weight: 700;
margin-left:1rem;
margin-top: 1rem;
line-height: normal;
}


.plan-type-text{
color: #1C1C1C;
font-family:"dmsans";
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.lose-text{
color: #A00;
font-family: "dmsans";
font-size: 0.625rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}

@media (min-width: 0px) and (max-width: 576px) {
   .membership-packages{
    flex-direction: column;
   }
  
  }