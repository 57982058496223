.first-text {
    color: #1C1C1C;
    font-family: "dmsans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 1rem;
}



.second-text {
    color: #1C1C1C;
    font-family: "dmsans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}



.ul-list {
    color: #1C1C1C;
    font-family: "dmsans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 1rem;
}


.long-para {
    color: #1C1C1C;
    font-family: "dmsans";
    width: 80%;
    flex-shrink: 0;
    font-size: 0.875rem;
    font-style: normal;
    margin-top: 1rem;
    font-weight: 400;
    line-height: normal;
}


.ul-list-second{
    color: #1C1C1C;
font-family: "dmsans";
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
margin-top: 1rem;
line-height: normal;
}