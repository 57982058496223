*,
**::after,
**::before{
  box-sizing: border-box;
  margin: 0;
  padding: 0;

}

body{
  margin: 0;
  font-family: "Inter", sans-serif;
}
