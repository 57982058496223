.mon-text{
color: #1C1C1C;
font-family:"boldsans";
font-size: 1.125rem;
font-style: normal;
line-height: normal;
margin-top: 4rem;
}


.label-text{
    color: #1C1C1C;
font-family: "dmsans";
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}