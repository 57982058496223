.data-upload {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.title-text {
    color: #1C1C1C;
    font-family: "dmsans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.data-date{
    margin-top: 5px;
    color: #1C1C1C;

font-family: "dmsans";
font-size: 0.6875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.back-container {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.back-button {
    justify-content: center;
    text-align: center;
    flex-shrink: 0;
    border-radius: 5px;
    width: 2rem;
    height: 2rem;
    border: 1px solid #1C1C1C !important;
}

.text-info {
    margin-left: 20px;
    text-align: center;
    justify-content: center;
    color: black !important;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}