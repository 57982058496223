.back-button-startup {
    justify-content: center;
    text-align: center;
    flex-shrink: 0;
    border-radius: 5px;
    width: 2rem;
    height: 2rem;
    border: 1px solid #1C1C1C !important;
}

