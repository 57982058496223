@font-face {
    font-family: 'dmsans';
    src: url(../../../public/DMSansFonts/DMSans_18pt-Regular.ttf);
}

.register-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.register-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-right: 10rem;
    margin-left: 10rem;

    justify-items: center;



}

.reg-login-text {
    color: #1C1C1C;
    text-align: center;
    margin-top: 1rem;

    font-family: "boldsans";
    font-size: 0.8rem;
    font-style: normal;
    line-height: normal;
}

.tos-privacy-text {
    color: #000;
    margin-top: 1rem;
    font-family: "dmsans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.button-wrapper {
    margin: 0 auto;
    padding-top: 2rem;
}

.form-control {
    background-color: transparent;
    color: rgba(28, 28, 28, 0.25) !important;
    font-family: "dmsans" !important;
    font-weight: 700;
    font-size: 14px !important;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 10px;

    border: 1px solid rgba(28, 28, 28, 0.2);

}

.text-overlay {
    position: absolute;
    color: white;
    font-size: 2rem;
    font-family: 'Inter';
    width: 566px;
    height: 255px;
    bottom: 60px;
    left: 220px;
}

.text-overlay-founder {
    position: absolute;
    color: white;
    font-family: 'Inter';
    font-size: 1.5rem;
    bottom: 70px;
    left: 320px;
}

.image-container {

    padding-left: 0.02rem;
    text-align: center;
    position: relative;
    color: white;
    width: 50%;
}

.background-container {
    width: 100%;
}


.user-profile-pic {
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    margin: auto;
    background-color: rgba(28, 28, 28, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    cursor: pointer;

}

p {
    color: red;
    padding: 0;
    margin: 0;
}


.reg-title {

    margin-bottom: 3rem;

}






@media (min-width: 0px) and (max-width: 576px) {
    .register-form {
        /* width: 50rem; */
        margin: 0 auto;
        padding: 1rem;



    }

    .user-profile-pic {
        width: 30vw;
        height: 20vh;
    }

    .reg-title {

        font-size: 1.5rem;

    }


    .image-container {
        display: none;
    }


}