@font-face {
    font-family: 'boldsans';
    src: url(../../../public/DMSansFonts/DMSans_18pt-Bold.ttf);
}
@font-face {
    font-family: 'dmsans';
    src: url(../../../public/DMSansFonts/DMSans_18pt-Regular.ttf);
}

@font-face {
    font-family: 'mdsans';
    src: url(../../../public/DMSansFonts/DMSans_18pt-Medium.ttf);
}

.user-image{
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    justify-items: center;
}

.MuiButton-textPrimary{
    color: black    ;
}
.user-profile-pic{
   
    /* max-width: 100%;
    width: 95px;
    height: 92px;
    display: inline; */
}

.profile-button{
display: flex;
flex-direction: column;
align-items: start;
justify-self: start;
}


.profile-title{
    font-family: 'boldsans';
    font-size: 1.125rem;
    color: black;
}

.MuIFormLabel{
    font-family: 'dmsans';
    font-size: 18rem;
    color: black;

}

