
  
  /* Style the main app container */
  .app-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
  

  
  /* Style the content container */
  .content-container {
    margin-top: 10px;
    display: flex;
    flex: 1; /* Allow the content container to grow and take up remaining space */
  }

/* Style the left sidebar */
.left-sidebar {
    color: black;
    padding: 1rem;
    position: fixed;
    top: 0;
    left: 0;
    top: 50px; /* Adjust the top value to match your header's height */
    bottom: 0;
    overflow-y: auto; /* Enable vertical scrolling if the content is taller than the viewport */
   
  }
  
  /* Style the main content */
  .main-content {
    flex: 1;
    margin-top:4rem;
    width: 100%;
    margin-left:18.5rem;
    padding: 1rem; /* Set the margin to match that of the sidebar */
    position: relative; /* Add relative positioning */
  }
  
  /* Style the right sidebar */
  .right-sidebar {
    color: black;
    padding: 1rem;
    position: fixed;
    right: 0;
    top: 60px; /* Adjust the top value to match your header's height */
    bottom: 0;
    overflow-y: auto; /* Enable vertical scrolling if the content is taller than the viewport */
    
  }
  
  

  
  /* Style the footer */
  .footer {
    background-color: #333;
    color: white;
    padding: 1rem;
  }
  

  @media (min-width: 0px) and (max-width: 576px) {
   

 
    

    .left-sidebar{
      display: none;
    }

    .main-content{
      margin: 0 auto;
    }


    .mobile-friendly{

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      justify-items: center;
      margin-top: 10%;
  }
 
   

   
}

@media only screen and (min-width: 1200px) {
  .left-sidebar{
      display: block;
  }
}   
