

.header-items {
  position: fixed;
  display: flex;
  width: 100%;
  height: 60px;
  margin-left: 10rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(28, 28, 28, 0.10);;
  
  
}


.user-text{
font-family: "dmsans";
font-size: 1.2rem;
}

.user{
  margin-right: 2rem;
}

.nav-links {
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  color: inherit;
  gap: 4rem;
}

.nav-menu-links {
  font-family: "dmsans";
  text-decoration: none;
  color: #1C1C1C;

}

.mem-border {
  border: 1px solid #1C1C1C;
  border-radius: 5px;
  padding: 0.2rem 0.2rem;
  text-decoration: none;
  color: #1C1C1C;
}

.nav-menu {

  color: #000;
  display: none;

}

.logo{
  color: #1C1C1C;

  font-family: "boldsans";
  font-size: 1.25rem;
  font-style: normal;
  margin-left: 2rem;
  font-weight: 700;
  line-height: normal;
}




@media (min-width: 0px) and (max-width: 576px) {
  

  .slack{
      display: none;
  }

  .logo{
      
      margin: 0 auto;
  }
  
  .header-items{
      position: relative;
  }

  .nav-menu {
      display: block;
      color: #000;
    
  }

  

 
}

.topBar {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #1c1c1c;
  border-bottom: 1px solid rgba(28, 28, 28, 0.1);
}


.offcanvas-header h1 {
  color: #1c1c1c;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}
.offcanvas-list {
  padding-left: 13px;
  padding-right: 13px;
}
.list-header {
  color: #1c1c1c;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 37px;
  margin-bottom: 5px;
  min-height: 33px;
  display: flex;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
}
.offcanvas-list ul {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0;
}
.list-item-name {
  color: #1c1c1c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 3px 14px;
  min-height: 34px;

  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;
}
.list-item-name:hover {
  border-radius: 8px;
  background: rgba(28, 28, 28, 0.05);
}

.list-item-name .label {
  color: #1c1c1c;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 3px;
  background: #e2fbd7;
  min-height: 18px;
}



@media (min-width: 0px) and (max-width: 576px) {
  

  .left-sidebar{
    display: none;
  }

  .main-content{
    margin: 0 auto;
  }

  .mobile-friendly{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    margin-top: 10%;
}

 
}
