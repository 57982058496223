.header-items {
    position: fixed;
    display: flex;
    width: 100%;
    height: 60px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border-bottom: 1px solid rgba(28, 28, 28, 0.10);;
    
    
}


.user-text{
font-family: "dmsans";
font-size: 1.2rem;
}

.user{
    margin-right: 2rem;
}

.nav-links {
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    color: inherit;
    gap: 4rem;
}

.nav-menu-links {
    font-family: "dmsans";
    text-decoration: none;
    color: #1C1C1C;

}

.mem-border {
    border: 1px solid #1C1C1C;
    border-radius: 5px;
    padding: 0.2rem 0.2rem;
    text-decoration: none;
    color: #1C1C1C;
}

.nav-menu {

    color: #000;
    display: none;

}

.logo{
    color: #1C1C1C;

    font-family: "boldsans";
    font-size: 1.25rem;
    font-style: normal;
    margin-left: 2rem;
    font-weight: 700;
    line-height: normal;
}




@media (min-width: 0px) and (max-width: 576px) {
    

    .slack{
        display: none;
    }

    .logo{
        
        margin: 0 auto;
    }
    
    .header-items{
        position: relative;
    }
 
    .nav-menu {
        display: block;
        color: #000;
      
    }

    

   
}