
.startup-border{
border-radius: '10px';
border: "1px solid var(--black-20, rgba(28, 28, 28, 0.20))";
width:'80%';

}

@media (min-width: 0px) and (max-width: 576px) {
  .startup-list{
    margin: 0 auto;
    width: auto;
    height: auto;
  }
  .startup-border{
    width: auto;
    height: auto;
  }

}