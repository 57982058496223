@font-face {
    font-family: 'dmsans';
    src: url(../../../public/DMSansFonts/DMSans_18pt-Regular.ttf);
}



/* .circle {
    display: flex;
    width: 100px;
    height: 100px;
    background-color: green;
    border-radius: 50%;
  } */



.circle {
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    /* padding: 0.4rem; */
    background: #fff;
    border: 0.15rem solid #000;
    color: #000;    
    /* text-align: center; */
    font: 0.4rem;
  }


.custom-font{
    font-family: "dmsans";
}

.fs-14{
    font-size: 14px;
}
.fs-18{
    font-size: 18px;

}
.fw-700{
    font-weight: 700;
}

.fs-color{
    color: rgba(28, 28, 28, 1);
}

.fw-400{
    font-weight: 400;
}

.fw-500{
    font-weight: 500;
}

.br-bottom
{
    border-bottom: 1px solid rgba(28, 28, 28, 0.1);
}


.membership-badge{
    background-color:  transparent !important;
    font-weight: 400;
    border-radius: 5px;
    border: 1px solid rgba(28, 28, 28, 1) ;
   box-shadow: 5px !important;
}

.arrow-up{
    transform: rotate(45deg);
}

.data-table{
    border: 1px solid rgba(28, 28, 28, 0.2);

    border-radius: 10px;
}

.custom-pogress-bar{
    --bs-progress-height: 2px;
    --bs-progress-bar-bg: rgba(11, 136, 0, 1);
}

.circular-badge{
    background-color: transparent !important;
    border:  1px solid   rgba(28, 28, 28, 1);
    font-size: 10px;
 
    

}

.circular-correct-badge{
    background-color: rgba(11, 136, 0, 1) !important;
    font-size: 10px;
}

.cl-green{
    color:  rgba(11, 136, 0, 1)  ;
}