    @font-face {
        font-family: 'dmsans';
        src: url(../../../public/DMSansFonts/DMSans_18pt-Regular.ttf);
    }

    .flex-item {
        flex: 1;
      
      }


    .login-main {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }

    .image-container {
        padding-left: 0.02rem;
        text-align: center;
        position: relative;
        color: white;
        width: 100%;
 
    }


    .error-text {
        padding-top: 0.2rem;
        color: red;
    }



    .forget-password {
        display: inline;
        color: black;
        margin-right: 13.5rem;
        margin-top: 2rem;
        font-family: 'boldsans';
        font-size: 0.8rem;
    }



    .login-text-overlay {
        position: absolute;
        width: 35.375rem;
        height: 12.9375rem;
        flex-shrink: 0;
        color: white;
        font-family: 'Inter';
        text-align: right;
        color: var(--white-100, #FFF);
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: 3.03125rem;
        letter-spacing: -0.1rem;
        bottom: 100px;
        right: 30px;
    }

    .login-text-overlay-founder {
        width: 35.375rem;
        height: 4.25rem;
        flex-shrink: 0;
        position: absolute;
        color: white;
        color: var(--white-100, #FFF);
        text-align: right;
        font-family: "Inter";
        font-size: 1.5625rem;
        font-style: normal;
        font-weight: 700;
        line-height: 3.03125rem;
        letter-spacing: -0.0625rem;
        bottom: 50px;
        right: 30px;

    }

    .title {
        margin-bottom: 6.6rem;


    }

    .form-label {
        font-family: "dmsans" !important;
        color: rgba(28, 28, 28, 1);
        font-weight: 700;
        font-size: 14px;
    }

    .form-control {
        background-color: transparent;
        color: rgba(28, 28, 28, 0.25) !important;
        font-family: "dmsans" !important;
        font-weight: 700;
        font-size: 14px !important;
        padding: 20px;
        border-radius: 20px;
        margin-bottom: 10px;

        border: 1px solid rgba(28, 28, 28, 0.2);

    }

    .form-control:focus {
        box-shadow: none;
    }

    .register {
        margin-top: 4rem;
        margin-right: 1.5rem;
    }

    .form-control::placeholder {
        color: rgba(28, 28, 28, 0.25) !important;
    }

    .btn-login {

        width: 35%;
        background-color: transparent;
        font-size: 14px !important;
        font-family: "dmsans";
        font-weight: 700;
        color: rgba(28, 28, 28, 1);
        border: 1px solid rgba(28, 28, 28, 1);
        border-radius: 5px;
        margin-top: 10px;

    }

    .form {

        display: flex;
        flex-direction: column;

    }

    .form-wrapper {
        width: 25%;
        margin: auto;
    }

    .button-wrapper {
        margin-top: 4rem;
    }

    .login-error {
        display: none;
        width: 18rem;
        margin-bottom: 3rem;
        color: red;
    }

    .login-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 10rem;
        margin-top: 2rem;
        justify-items: center;
        flex:1;
    }


    @media (min-width: 0px) and (max-width: 576px) {
        .login-container {
            padding: 1rem;
            margin: 0 auto;
        }

        .title {
            font-size: 1.5rem;
            margin-bottom: 2rem;

        }


        .image-container {
            display: none;
        }

       
        .register {
            margin-top: 2rem;
        }

        .button-wrapper{
            margin-top: 1rem;
        }


        .forget-password{
            margin-top: 0.5rem;
        }

    }