#app-receive{
    margin-top: 5rem;
    color: #1C1C1C;
    font-family: "dmsans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#app-receive-info{
color: #1C1C1C;
text-align: left;
padding-right: 10rem;
margin-top: 0.5rem;
font-family:"dmsans";
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}