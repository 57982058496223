@font-face {
    font-family: 'dmsans';
    src: url(../../../public/DMSansFonts/DMSans_18pt-Regular.ttf);
}

.MuiSlider-root {
    color: #111515;
  }
.disable-links{
    pointer-events: none;
}

.side-nav-wrapper {
    margin: auto;
    width: 100%;
 padding-left: 1rem;
    /* width: 15rem;
    background-color:rgba(28, 28, 28, 0.01); */

}



.main-title {
    font-family: "dmsans";
    font-weight: 900;
    font-size: 0.9rem;
    padding-bottom: 5px;
    border-radius: 8px;
    color: black;
  
}


.side-link {
    font-family: "dmsans";
    font-weight: 400;
    font-size: 0.9rem;
    padding-top: 14px;
    padding-bottom: 8px;
    /* padding-left: 15px;
    padding-right: 15px; */
    border-radius: 8px;

    color: rgba(28, 28, 28, 1);
}

.side-link.active {
    background-color: rgba(28, 28, 28, 0.05);
   
}


.version {
    display: flex;
    justify-content: center;
    bottom: 0;
    font-size: 9px;
    font-family: "dmsans";
    font-weight: 400;
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 8px;

}


.u-link {
    text-decoration: none !important;

}

@media (min-width: 0px) and (max-width: 576px) {
     .logo{
        width: 100%;
        padding-left: 1.125rem;
        margin: 0 auto;

    }

    .side-nav-wrapper{
        width: 85%;
        padding-left: 0; 
        
    }
  
 
    

}

